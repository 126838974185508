<template>
  <div class="container">
    <h2>{{ this.loggedUser.username }}</h2>
    <h4>{{ dayWeek }}, {{ day }} {{ month }} {{ year }}</h4>
    <div>
      <div class="dashboard">
          <div class="card-container">
            <div class="card">
              <img src="https://st.depositphotos.com/2069323/2156/i/600/depositphotos_21565261-stock-photo-shopping-woman-holding-shopping-bags.jpg" class="card-img-top" alt="" />
              <div class="card-body">
                <h5 class="card-title">Fazer Compras</h5>
                <p class="card-text">Ver produtos em falta</p>
                <a href="/purchases/trans" class="btn btn-primary">Comprar</a>
              </div>
            </div>
          
          <div class="col">
            <div class="card">
              <img src="https://mapa-da-obra-producao.s3.amazonaws.com/wp-content/uploads/2017/08/veja-como-organizar-o-cadastro-de-fornecedores.jpg" class="card-img-top" alt="" />
              <div class="card-body">
                <h5 class="card-title">Fornecedores</h5>
                <p class="card-text">Informações fornecedores</p>
                <a href="/suppliers" class="btn btn-primary">Abrir</a>
              </div>
            </div>
          </div>
            <div class="card">
              <img src="https://storage.googleapis.com/meetime-blog/wp-content/uploads/2022/08/9ad1ce51-relatorio.png" class="card-img-top" alt="" />
              <div class="card-body">
                <h5 class="card-title">Vendas</h5>
                <p class="card-text">Relatório de vendas</p>
                <a href="/reportSales" class="btn btn-primary">Abrir</a>
              </div>
            </div>
          </div>
          <div class="col"></div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
export default {
  data: function () {
    return {
      loggedUser: [],
      totalProcessing: 0,
      totalPicking: 0,
      percProcessing: 0,
      dayWeek: '',
      day: '',
      month: '',
      year: '',
      allMonths: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outobro', 'Novembro', 'Dezembro'],
    };
  },
  methods: {
    async getUserInformation() {
      this.loggedUser = this.getLoggedUser;
      if (this.loggedUser.length == 0) {
        let token = localStorage.getItem('token');
        let jwt = token.split('.')[1];
        this.loggedUser = JSON.parse(window.atob(jwt));
      }
      console.log(this.loggedUser.username);
    },
    async getTotalProcessing() {
      await this.$store.dispatch('fetchOrdersProcessing');
      this.totalProcessing = Number(this.getOrderProcessing);
      console.log(this.totalProcessing);
    },
    async getAllOrdersPicking() {
      await this.$store.dispatch('fetchOrderPicking');
      this.totalPicking = Number(this.getTotalPicking);
      console.log(this.totalPicking);
      this.percProcessing = Number(((this.totalProcessing - this.totalPicking) * 100) / this.totalProcessing);
      console.log(Number(this.percProcessing));
    },
    getDay() {
      let date = new Date();
      let weekDay = date.getDay();
      this.day = date.getDate();
      let mon = date.getMonth();
      this.year = date.getFullYear();
      switch (weekDay) {
        case 1:
          this.dayWeek = 'Segunda-Feira';
          break;
        case 2:
          this.dayWeek = 'Terça-Feira';
          break;
        case 3:
          this.dayWeek = 'Quarta-Feira';
          break;
        case 4:
          this.dayWeek = 'Quinta-Feira';
          break;
        case 5:
          this.dayWeek = 'Sexta-Feira';
          break;
        case 6:
          this.dayWeek = 'Sábado';
          break;
        case 7:
          this.dayWeek = 'Domingo';
          break;
      }
      this.month = this.allMonths[mon];
    },
  },
  created() {
    this.getUserInformation();
    this.getTotalProcessing();
    this.getAllOrdersPicking();
    this.getDay();
  },
  computed: {
    ...mapGetters(['getLoggedUser']),
    ...mapGetters(['getOrderProcessing']),
    ...mapGetters(['getTotalPicking']),
  },
};
</script>
<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800;900&display=swap');

h2 {
  margin: 50px 50px 0px 0px;
}
h4 {
  margin: 0px 50px 50px 0px;
}

.btn {
  margin-top: 10px;
  float: right;
  background: rgb(255, 147, 147);
  border: none;
  box-shadow: none !important;
}
.btn:hover,
.btn:focus {
  background: rgba(255, 147, 147, 0.658);
  border: white;
}

.card {
  margin-top: 10px;
  border-radius: 10px;
}
img {
  height: 200px;
  object-fit: cover;
  object-position: 0% 50;
}

@media (max-width: 767px) {
  body {
    padding: 10px;
  }

  .body {
    width: 100%;
    height: 100%;
  }
}

.dashboard {
  padding: 10px;
  margin-bottom: 20px;
}

.card-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);   /*linhas de 3 e nao 4 como o resto pq tem menos cards nesta pagina*/
  gap: 20px;
}

.container{
  max-width: 90%;
}
</style>
